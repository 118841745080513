import { Patient } from "../../../utils/data-classes/Patient";
import useRequestedChallenges from "../../../hooks/useRequestedChallenges";
import { RequestedChallenges } from "../../../utils/data-classes/RequestedChallenges";
import s from "./Challenges.module.scss";
import { ChallengePromptContextProvider } from "../../../components/dialogs/ChallengePromptModal/ChallengePromptContext";
import { ChallengePromptModal } from "../../../components/dialogs/ChallengePromptModal/ChallengePromptForm";
import CancelTestButton from "../../../components/buttons/CancelTestButton/CancelTestButton";

interface RequestedChallengesCardProps {
  patient: Patient | undefined;
}

export default function RequestedChallengesCard(
  props: RequestedChallengesCardProps
) {
  const { patient } = props;
  const { requestedChallenges } = useRequestedChallenges(patient?.ptid);
  return (
    <RequestedChallengesStrip
      patient={patient}
      requestedChallenges={requestedChallenges}
    />
  );
}

interface RequestedChallengesStripProps {
  patient: Patient | undefined;
  requestedChallenges: RequestedChallenges | null;
}

function RequestedChallengesStrip(props: RequestedChallengesStripProps) {
  const { patient, requestedChallenges } = props;
  if (requestedChallenges) {
    return (
      <div className={`${s.challengeCard}`}>
        <div className={s.chalInfo}>
          <span></span>
          <span>{requestedChallenges?.crExtra}</span>
          <span>
            <ChallengePromptContextProvider>
              <CancelTestButton
                title="Cancel requested test"
                action="CANCEL"
                patient={patient}
                requestedChallenge={requestedChallenges?.crExtra}
              >
                Cancel Test
              </CancelTestButton>
              <ChallengePromptModal />
            </ChallengePromptContextProvider>
          </span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
