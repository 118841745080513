import { useContext } from "react";
import { ChallengePromptAction } from "../../dialogs/ChallengePromptModal/ChallengePromptContext";
import { Patient } from "../../../utils/data-classes/Patient";
import { ChallengePromptContext } from "../../dialogs/ChallengePromptModal/ChallengePromptContext";
import InterfaceButton, {
  InterfaceButtonProps,
} from "../InterfaceButton/InterfaceButton";
import { ReactComponent as CancelIcon } from "./bin.svg";

type CancelTestButtonProps = {
  action: ChallengePromptAction;
  patient: Patient | undefined;
  requestedChallenge?: string | undefined;
} & Omit<
  InterfaceButtonProps,
  "type" | "label" | "icon" | "loading" | "onClick"
>;

function CancelTestButton(props: CancelTestButtonProps) {
  const { action, patient, requestedChallenge, ...rest } = props;
  const { dispatch } = useContext(ChallengePromptContext);
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!patient) {
      return;
    }
    dispatch({
      type: "INIT_CHALLENGE_FORM",
      payload: {
        action,
        patient,
        requestedChallenge,
      },
    });
  };
  return (
    <InterfaceButton
      type="button"
      onClick={onClick}
      icon={<CancelIcon />}
      {...rest}
    />
  );
}

export default CancelTestButton;
