import React, { useContext } from "react";
import {
  ChallengePromptAction,
  ChallengePromptContext,
} from "../../dialogs/ChallengePromptModal/ChallengePromptContext";
import { Patient } from "../../../utils/data-classes/Patient";
import InterfaceButton, {
  InterfaceButtonProps,
} from "../InterfaceButton/InterfaceButton";
import { ReactComponent as AddIcon } from "./add-icon.svg";

type ScheduleTestButtonProps = {
  action: ChallengePromptAction;
  patient: Patient;
  requestedChallenge?: string | undefined;
} & Omit<
  InterfaceButtonProps,
  "type" | "label" | "icon" | "loading" | "onClick"
>;

export default function ScheduleTestButton(props: ScheduleTestButtonProps) {
  const { action, patient, requestedChallenge, disabled, ...rest } = props;
  const { dispatch } = useContext(ChallengePromptContext);
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: "INIT_CHALLENGE_FORM",
      payload: {
        action,
        patient,
        requestedChallenge,
      },
    });
  };
  return (
    <InterfaceButton
      type="button"
      icon={<AddIcon />}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    />
  );
}
