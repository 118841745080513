import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { ClinicianRequestType } from "../../../utils/data-classes/ClinicianRequestType";
import { Patient } from "../../../utils/data-classes/Patient";
import { AppError } from "../../../utils/utils";
import { ChallengePromptFormFieldValues } from "./ChallengePromptForm";
import useClinicianRequestTypes from "../../../hooks/useClinicianRequestTypes";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { deleteChallengeRequest, sendChallengePrompt, sendChallengeReminder } from "./challenge-prompt-util";
import { DEFAULT_ERROR, createErrorToDisplay, devErrorLog } from "../../../hooks/useErrorState";

export interface ChallengePromptContextState {
  action: ChallengePromptAction | undefined;
  clinicianRequestTypesMap: Partial<Record<number, ClinicianRequestType>> | undefined;
  patient: Patient | undefined;
  requestedChallenge: string | undefined;
  promptedPatients: Patient[];
  formData: ChallengePromptFormFieldValues | undefined;
  processing: boolean;
  success: boolean;
  error: AppError | undefined;
}

const initialState: ChallengePromptContextState = {
  action: undefined,
  clinicianRequestTypesMap: undefined,
  patient: undefined,
  requestedChallenge: undefined,
  promptedPatients: [],
  formData: undefined,
  processing: false,
  success: false,
  error: undefined,
};

export type ChallengePromptAction = "CREATE" | "REMIND" | "CANCEL";

export type ChallengePromptContextAction =
  | { type: "SET_CLINICIAN_REQUEST_TYPES"; payload: ClinicianRequestType[] }
  | {
      type: "INIT_CHALLENGE_FORM";
      payload: { action: ChallengePromptAction; patient: Patient; requestedChallenge: string | undefined };
    }
  | { type: "RESET" }
  | { type: "ADD_PROMPTED_PATIENT"; payload: Patient }
  | { type: "SET_ERROR"; payload: any }
  | { type: "SUCCESS" }
  | {
      type: "DO_ACTION";
      payload: { action: "REMIND" | "CANCEL" } | { action: "CREATE"; formData: ChallengePromptFormFieldValues };
    };

const reducer = (
  state: ChallengePromptContextState,
  action: ChallengePromptContextAction
): ChallengePromptContextState => {
  switch (action.type) {
    case "SET_CLINICIAN_REQUEST_TYPES":
      return {
        ...state,
        clinicianRequestTypesMap: Object.fromEntries(action.payload.map((type) => [type.crtId, type])),
      };
    case "INIT_CHALLENGE_FORM":
      return { ...state, ...action.payload };
    case "RESET":
      if (state.success && state.action === "CANCEL") {
        window.location.reload();
      }
      return {
        ...state,
        action: undefined,
        patient: undefined,
        requestedChallenge: undefined,
        processing: false,
        success: false,
        error: undefined,
      };
    case "ADD_PROMPTED_PATIENT":
      return { ...state, promptedPatients: [...state.promptedPatients, action.payload] };
    case "SET_ERROR":
      return { ...state, processing: false, success: false, error: getContextDisplayError(action) };
    case "SUCCESS":
      return { ...state, processing: false, success: true, error: undefined };
    case "DO_ACTION":
      const formData = action.payload.action === "CREATE" ? action.payload.formData : undefined;
      return { ...state, processing: true, formData };
  }
};

export interface ChallengePromptContextType {
  state: ChallengePromptContextState;
  dispatch: React.Dispatch<ChallengePromptContextAction>;
}

export const ChallengePromptContext = createContext<ChallengePromptContextType>({
  state: initialState,
  dispatch: () => {},
});

export const ChallengePromptContextProvider = ({
  preReg = false,
  children,
}: {
  preReg?: boolean;
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer<React.Reducer<ChallengePromptContextState, ChallengePromptContextAction>>(
    reducer,
    initialState
  );

  const { loginToken } = useContext(LoginContext).state;
  const { clinicianRequestTypes, error: clinicianRequestTypesError } = useClinicianRequestTypes();

  useEffect(() => {
    if (!clinicianRequestTypes) return;
    dispatch({ type: "SET_CLINICIAN_REQUEST_TYPES", payload: clinicianRequestTypes });
  }, [clinicianRequestTypes]);

  useEffect(() => {
    if (!clinicianRequestTypesError) return;
    dispatch({ type: "SET_ERROR", payload: clinicianRequestTypesError });
  }, [clinicianRequestTypesError]);

  useEffect(() => {
    if (!state.processing || !loginToken) return;
    const abortController = new AbortController();
    doAction(state, dispatch, loginToken, preReg, abortController);
    return () => abortController.abort();
  }, [state.processing]);

  return <ChallengePromptContext.Provider value={{ state, dispatch }}>{children}</ChallengePromptContext.Provider>;
};

function getContextDisplayError<TAction extends { payload: any }>(action: TAction, defaultError = DEFAULT_ERROR) {
  const realError = action.payload;
  const displayError = createErrorToDisplay(realError, defaultError);
  if (!displayError) return undefined;
  devErrorLog(realError, displayError);
  return displayError;
}

const doAction = async (
  state: ChallengePromptContextState,
  dispatch: React.Dispatch<ChallengePromptContextAction>,
  loginToken: string,
  preReg: boolean,
  abortController: AbortController
) => {
  const { processing, success, error, action, patient, promptedPatients, formData, requestedChallenge } = state;
  try {
    if (!processing || !action || !patient) throw new Error("Missing necessary state values for action");
    if (error) throw new Error("Cannot submit with existing error state");
    if (success) throw new AppError(401, "Action has already been completed");
    if (action === "CREATE" || action === "REMIND") {
      if (!preReg && !patient.uid) throw new AppError(400, "Patient has not completed registration.");
      if (promptedPatients.includes(patient))
        throw new AppError(403, "Patient has already been prompted to complete a Test this session.");
    }
    switch (action) {
      case "CREATE":
        if (!formData) throw new Error("Form data not provided");
        await sendChallengePrompt(loginToken, patient, formData, abortController.signal);
        break;
      case "REMIND":
        if (!requestedChallenge) throw new Error("Missing requestedChallenge");
        await sendChallengeReminder(loginToken, patient, requestedChallenge, abortController.signal);
        break;
      case "CANCEL":
        if (!requestedChallenge) throw new Error("Missing requestedChallenge");
        await deleteChallengeRequest(loginToken, patient, requestedChallenge, abortController.signal);
        break;
    }
    dispatch({ type: "SUCCESS" });
  } catch (err) {
    dispatch({ type: "SET_ERROR", payload: err });
  } finally {
    if (patient && (action === "CREATE" || action === "REMIND")) {
      dispatch({ type: "ADD_PROMPTED_PATIENT", payload: patient });
    }
  }
};
