import React, { useContext, useState } from "react";
import Modal from "../../../../../components/modal/Modal";
import { Form } from "../../../../../components/Form/Form";
import Input from "../../../../../components/inputs/Input/Input";
import styles from "./AddBillingModal.module.scss";
import { BillingDetails } from "../BillingTable";
import { LoginContext } from "../../../../../contexts/LoginContext/LoginContext";
import { authFetch } from "../../../../../utils/utils";
import { Dropdown } from "../../../../../components/dropdown/Dropdown";
import useCountries from "../../../../../hooks/useCountries";
import { IoIosArrowDown } from 'react-icons/io';
import toast from "react-hot-toast";
import { CountryCode } from 'libphonenumber-js';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import phoneStyles from "../../../../../components/inputs/PhoneInput/PhoneInput.module.scss";

const AddBillingModal: React.FC<AddBillingModalProps> = ({
  isOpen,
  onClose,
  providerId,
  onAdd,
}) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { countries } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<string>("United States of America");
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');

  const handleFormCancel = () => {
    onClose();
  };

  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
    setPhoneNumber('');
    setPhoneError('');
  };

  const getCountryCode = (countryName: string): CountryCode => {
    const country = countries?.find(c => c.label === countryName);
    return (country?.country || 'US') as CountryCode;
  };

  const validatePhoneNumber = (): boolean => {
    if (!phoneNumber) {
      setPhoneError('Phone number is required');
      return false;
    }

    // if (!isPossiblePhoneNumber(phoneNumber)) {
    //   setPhoneError('Please enter a valid phone number');
    //   return false;
    // }

    if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneError('Please enter a valid phone number');
      return false;
    }

    setPhoneError('');
    return true;
  };

  const handleSubmit = async (data: Partial<BillingDetails>) => {
    if (!validatePhoneNumber()) {
      return;
    }

    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }

    setIsSubmitting(true);
    try {
      const selectedCountryData = countries?.find(c => c.label === selectedCountry);
      console.log('phonenumber--->', phoneNumber);
      const stripeResponse = await authFetch(loginToken, '/api/create-stripe-customer-for-provider', {
        method: 'POST',
        body: JSON.stringify({
          billing: {
            name: data.billingName,
            email: data.billingEmail,
            phone: phoneNumber,
            address: data.billingAddress1,
            city: data.billingCity,
            countryCode: selectedCountryData?.country,
            zipCode: data.billingZip,
            stateCode: data.billingState,
          }
        })
      });

      if (!stripeResponse.ok) {
        throw new Error('Failed to create Stripe customer');
      }

      const stripeData = await stripeResponse.json();
      
      const submissionData = {
        ...data,
        billingPhone: phoneNumber,
        billingCountry: selectedCountryData?.country,
        stripeCustomerKey: stripeData.stripeCustomerKey,
        providerId,
      };

      const response = await authFetch(loginToken, `/api/add-staff-billing`, {
        method: "POST",
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        const newBilling = await response.json();
        toast.success('Billing details added successfully');
        onAdd(newBilling.billing);
        onClose();
      } else {
        toast.error(`Failed to add billing details: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error adding billing details: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Add Billing Details"
      open={isOpen}
      onCancel={onClose}
    >
      <Form<Partial<BillingDetails>>
        onSubmit={handleSubmit}
        footer={{
          onCancel: handleFormCancel,
          submitText: "Add Billing Details",
          loadingText: "Adding",
          isSubmitting,
        }}
      >
        <Input 
          name="billingName" 
          label="Billing Name" 
          placeholder="Name (e.g. 'John Doe')"
          rules={{ required: 'Billing name is required' }} 
          required
        />
        <div className={styles.inputGroup}>
          <Input 
            name="billingEmail" 
            label="Email" 
            type="email"
            placeholder="Email (e.g. 'johndoe@example.com')"
            rules={{ 
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address"
              }
            }} 
            required
          />
          <div className={phoneStyles.phoneInputWrapper}>
            <label className={phoneStyles.label}>Phone Number *</label>
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              country={getCountryCode(selectedCountry)}
              value={phoneNumber}
              onChange={(value) => {
                setPhoneNumber(value || '');
                if (isValidPhoneNumber(value || '')) setPhoneError('');
              }}
              required
              defaultCountry={getCountryCode(selectedCountry)}
              disabled={!selectedCountry}
              countrySelectProps={{ disabled: true }}
            />
            {phoneError && (
              <span className={phoneStyles.errorMessage}>{phoneError}</span>
            )}
          </div>
        </div>           
        <Input 
          name="billingAddress1" 
          label="Address" 
          placeholder="Address (e.g. '123 Main Street')"
          rules={{ required: 'Address is required' }} 
          required
        />
        <div className={styles.inputGroup}>
          <Input 
            name="billingCity" 
            label="City" 
            placeholder="City (e.g. 'San Francisco')"
            rules={{ required: 'City is required' }} 
            required
          />
          <Input 
            name="billingState" 
            label="State / Province"
            placeholder={selectedCountry === "United States of America" ? "State (e.g. 'CA')" : "Province (e.g. 'Ontario')"}
            rules={{ required: selectedCountry === "United States of America" ? 'State is required' : undefined }}
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <Input 
            name="billingZip" 
            label="Postal Code" 
            placeholder="Postal Code (e.g. '95132')"
            rules={{ required: 'Postal code is required' }} 
            required
          />
          <div className={styles.dropdownWrapper}>
            <label htmlFor="billingCountry" className={styles.label}>Country *</label>
            <Dropdown
              menu={{
                items: countries?.map(country => country.label) || [],
                onSelect: handleCountrySelect,
              }}
              size="small"
            >
              <div className={styles.preferenceValue}>
                {selectedCountry || 'Select Country'}
                <span className={styles.iconWrapper}>
                  <IoIosArrowDown />
                </span>
              </div>
            </Dropdown>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddBillingModal;
interface AddBillingModalProps {
  isOpen: boolean;
  onClose: () => void;
  providerId: string;
  onAdd: (data: BillingDetails) => void;
}
