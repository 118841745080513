import { useContext, useState, useEffect } from "react";
import {
  RequestedChallenges,
  createRequestedChallenges,
} from "../utils/data-classes/RequestedChallenges";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { AppError, authFetch } from "../utils/utils";

interface useRequestedChallengeProps {
  requestedChallenges: RequestedChallenges | null;
  error: AppError | null;
}

export default function useRequestedChallenges(
  ptid: number | undefined | null
): useRequestedChallengeProps {
  const {
    state: { loginToken },
  } = useContext(LoginContext);
  const [requestedChallenges, setRequestedChallenges] =
    useState<RequestedChallenges | null>(null);
  const [error, setError] = useState<AppError | null>(null);
  useEffect(() => {
    if (!ptid || !loginToken) return;
    const abortController = new AbortController();
    fetchRequestedChallenges(loginToken, ptid, abortController.signal)
      .then(setRequestedChallenges)
      .catch((err) =>
        setError(
          err instanceof AppError
            ? err
            : new AppError(400, "Something went wrong")
        )
      );
  }, [ptid, loginToken]);
  return { requestedChallenges, error };
}

async function fetchRequestedChallenges(
  loginToken: string,
  ptid: number,
  signal: AbortSignal
) {
  const reponse = await authFetch(
    loginToken,
    `/api/challenge-requests/${ptid}`,
    { method: "GET", signal }
  );
  const data = await reponse.json();
  const error = data.error;
  if (error !== undefined) throw new AppError(reponse.status, error);
  return createRequestedChallenges(data.requestedChallenges);
}
