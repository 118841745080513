export class RequestedChallenges {
  readonly crId: number;
  readonly createdOn: string;
  readonly crType: number;
  readonly crExtra: string;
  readonly stfId: number;
  readonly ptId: number;
  readonly challengeCreatedOn: string;
  readonly referringStfId: number;

  constructor(
    crId: number,
    createdOn: string,
    crType: number,
    crExtra: string,
    stfId: number,
    ptId: number,
    challengeCreatedOn: string,
    referringStfId: number
  ) {
    this.crId = crId;
    this.createdOn = createdOn;
    this.crType = crType;
    this.crExtra = crExtra;
    this.stfId = stfId;
    this.ptId = ptId;
    this.challengeCreatedOn = challengeCreatedOn;
    this.referringStfId = referringStfId;
  }
}

export const createRequestedChallenges = ({
  crId,
  createdOn,
  crType,
  crExtra,
  stfId,
  ptId,
  challengeCreatedOn,
  referringStfId,
}: any) => {
  return new RequestedChallenges(
    crId,
    createdOn,
    crType,
    crExtra,
    stfId,
    ptId,
    challengeCreatedOn,
    referringStfId
  );
};
