import { Image, Text, View } from "@react-pdf/renderer";
import ProviderLogo from "../../../ProviderLogo";
import foodmarbleHealthcareLogo from "../../../../assets/provider-logos/foodmarble-healthcare.png";
import { shared, s } from "../challenge-report-styles";
import { ChallengeReportProps } from "../ChallengeReport";
import { Provider } from "../../../../utils/data-classes/Provider";
import { ReactComponent as Heading } from "./test-report-heading.svg";
import SvgComponent from "../../../SvgComponent";

export function HeaderPageOne(props: ChallengeReportProps) {
  return (
    <View style={s.headerPageOne.main}>
      <View style={s.headerPageOne.title}>
        <SvgComponent ReactSvgComponent={<Heading/>}/>
      </View>
      <HeaderPageOneLogos {...props} />
    </View>
  );
}

function HeaderPageOneLogos({ provider, logoSrc }: ChallengeReportProps) {
  return (
    <View style={s.headerPageOne.logos}>
      <View style={shared.foodmarbleLogo}><Image src={foodmarbleHealthcareLogo} /></View>
      {showSecondLogo(provider) && (<>
        <View style={s.headerPageOne.border} />
        <View style={shared.providerLogo}><ProviderLogo provider={provider} logoSrc={logoSrc} /></View>
      </>)}
    </View>
  );
}

export function HeaderPageTwo({ provider, logoSrc }: ChallengeReportProps) {
  return (
    <View style={s.headerPageTwo.main}>
      <View style={shared.foodmarbleLogo}><Image src={foodmarbleHealthcareLogo} /></View>
      {showSecondLogo(provider) && <View style={shared.providerLogo}><ProviderLogo provider={provider} logoSrc={logoSrc} /></View>}
    </View>
  );
}

function showSecondLogo(provider: Provider): boolean {
  return !!provider.logo && provider.prid !== 2;
}