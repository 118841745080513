import React, { useEffect } from "react";
import { useContext } from "react";
import { DateTime } from "luxon";
import s from "./Challenges.module.scss";
import DashLayout from "../../../layouts/dash/DashLayout";
import DigestHeader from "../../../components/digest-header/DigestHeader";
import ChalCard from "./ChalCard";
import ChalCardHeader from "./ChalCardHeader";
import { ChallengeReportCombinedDocument } from "../../../pdf-generator/templates/ChallengeReportDocument/ChallengeReportDocument";
import { buildDashURL, logCdAction, createURLSearchParams } from "../../../utils/utils";
import { CdAction } from "../../../utils/CdActions";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import ErrorPage from "../../../components/errors/ErrorPage/ErrorPage";
import { Patient } from "../../../utils/data-classes/Patient";
import { Provider } from "../../../utils/data-classes/Provider";
import { LoginRoutes } from "../../../routers/DashRouter";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { dateFormat } from "../../../hooks/useDateFormat";
import PdfDownloadButton from "../../../components/buttons/PdfDownloadButton/PdfDownloadButton";
import ChallengeReviewButton from "../../../components/buttons/ChallengeReviewButton/ChallengeReviewButton";
import { ChallengesContext, ChallengesContextProvider, useChosenCards } from "./ChallengesContext";
import ScheduleTestButton from "../../../components/buttons/ScheduleTestButton/ScheduleTestButton";
import { ChallengePromptModal } from "../../../components/dialogs/ChallengePromptModal/ChallengePromptForm";
import { ChallengePromptContextProvider } from "../../../components/dialogs/ChallengePromptModal/ChallengePromptContext";
import RequestedChallengesCard from "./RequestedChallengesCard";
import useRequestedChallenges from "../../../hooks/useRequestedChallenges";

const Challenges = () => {
  return (
    <ChallengesContextProvider>
      <_Challenges />
    </ChallengesContextProvider>
  );
};

const _Challenges = () => {
  const { patient, error } = useContext(ChallengesContext).state;
  const { loginToken, provider } = useContext(LoginContext).state;
  const chosenCards = useChosenCards();
  const shoulDisableButtons = chosenCards.length === 0;
  const { requestedChallenges } = useRequestedChallenges(patient?.ptid);
  const hasRequestedChallenge = requestedChallenges ? true : false;

  const pdf = ChallengeReportCombinedDocument();

  if (error) return <ErrorPage code={error.code} message={error.message} />;

  if (!patient || !provider || !loginToken || !pdf) return <LoadingPage />;

  return (
    <DashLayout className={s.challengesLayout}>
      <div className={s.challengesContainer}>
        <div className={s.desc}>
          <DigestHeader patient={patient} />
          <p>
            This page shows all of the tests that have been completed by this patient.<br/>
            Click any row to view a chart of the results.<br/>
            Use the check boxes and buttons to download and mark reports as reviewed.<br/>
          </p>
          <div className={s.pdfDownloadButtonContainer}>
            <ChallengePromptContextProvider>
              <ScheduleTestButton
                title={hasRequestedChallenge ? "Patient has already been prompted to complete a Test":"Prompt patient to complete a test"}
                action="CREATE" 
                patient={patient}
                disabled={hasRequestedChallenge}
                >
                  Schedule test
              </ScheduleTestButton>
              <ChallengePromptModal />
            </ChallengePromptContextProvider>
            <ChallengeReviewButton disabled={shoulDisableButtons} cards={chosenCards} />
            <PdfDownloadButton
              title="Download Combined Challenge Report"
              filename={filename(patient, provider)}
              document={pdf}
              isDocumentDataReady={!!pdf}
              disabled={shoulDisableButtons}
              onDownload={() => {
                return logCdAction(loginToken, CdAction.DOWNLOAD_COMBINED_CHALLENGE_REPORT, patient.ptid.toString());
              }}
            >
              Download
            </PdfDownloadButton>
          </div>
        </div>
        <ChallengesList />
      </div>
    </DashLayout>
  );
};

function ChallengesList() {
  const {
    state: { fetching, cards, patient },
  } = useContext(ChallengesContext);
  if (fetching) return <LoadingSpinner />;
  if (!cards?.length) return <p>No challenges to show</p>;
  return (
    <div className={s.challengeList}>
      <ChalCardHeader />
      <RequestedChallengesCard patient={patient} />
      {cards.map((card) => (
        <ChalCard key={card.index} card={card} />
      ))}
    </div>
  );
}

const filename = (patient: Patient, provider: Provider): string => {
  const { lastName, identifier } = patient;
  const date = DateTime.now().toFormat(dateFormat(provider).date).toString().replace(/\//g, "_");
  const name = lastName ? lastName.replace(/ /g, "_") : identifier;
  const result = `${date}_${name}_Combined_Challenge_Report.pdf`;
  return result;
};

export const buildChallengesURL = (ptid: number, chalEpochMs: number | undefined) => {
  return buildDashURL(LoginRoutes.CHALLENGES.path, createURLSearchParams({ ptid, chalEpochMs }));
};

function LoadingPage() {
  return (
    <DashLayout className={s.challengesLayout}>
      <div className={s.challengesContainer}>
        <LoadingSpinner />
      </div>
    </DashLayout>
  );
}

export default Challenges;
