import React, { useContext } from "react";
import s from "./Home.module.scss";
import { useState } from "react";
import { ReactComponent as ResendInviteIcon } from "../../assets/icons/resend-invite.svg";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { APIRequest, DashAPIRoute } from "../../utils/apis";
import TooltipButton from "../../components/buttons/TooltipButton/TooltipButton";
import { Patient } from "../../utils/data-classes/Patient";
import { authFetch } from "../../utils/utils";

/**
 * @param {Object} props
 * @param {Patient} props.patient;
 * @returns 
 */
const InviteButton = ({ patient }) => {
  const { state: { loginToken : token} } = useContext(LoginContext);
  const [sent, setSent] = useState(false);
  const title = sent ? "Patient invite already sent" : "Resend patient invite";

  return (
    <TooltipButton
      tooltipMessage={title}
      onClick={handleClick(token,patient,setSent)}
      disabled={sent}
      title={title}
    >
      <ResendInviteIcon width={30} height={30} />
    </TooltipButton>
  );
}

const handleClick = (token,patient,setSent) => async e => {
  e.preventDefault();

  const { ptid } = patient;
  const res = await authFetch(token,DashAPIRoute.INVITE_PATIENT.path, {
    method: "POST",
    body: JSON.stringify({ptid}),
  });
  const { success, error } = await res.json();

  setSent(true);
}

export default InviteButton;

